import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBarCollapse from "./AppBarCollapse";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const Navbar = () => {

  const { information } = useSelector(state => state.information);
  return (
    <AppBar className="bg-green2" position="sticky">
      <Toolbar>

        <Typography className="ui__navbar-text-left pointer">
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
            {information.name}
          </Link>
        </Typography>

        <AppBarCollapse />
      </Toolbar>
    </AppBar>
  );
};
