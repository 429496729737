//fonts
import './fonts/AirbnbCerealBlack.woff';
import './fonts/AirbnbCerealBold.woff';
import './fonts/AirbnbCerealBook.woff';
import './fonts/AirbnbCerealExtraBold.woff';
import './fonts/AirbnbCerealLight.woff';
import './fonts/AirbnbCerealMedium.woff';
//animations
import AOS from 'aos';
import 'aos/dist/aos.css';
//init AOS
AOS.init();