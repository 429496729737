import { Box, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Footer } from '../ui/footer/Footer';
import { Navbar } from '../ui/navbar/Navbar';

export const ContactScreen = () => {
    const { information: { muralImageUrl } } = useSelector(state => state.information);
    return (
        <div className="main__overflow-hidden bg-white" >
            <Navbar />
            <Container>
                <Grid container>
                    <Grid item md={12} lg={12} xs={12} sm={12}>

                        <Box className='main__jumbotron-text' p={5}>
                            <h1>Contáctanos</h1>
                            <p className='mt-3'>La mejor forma de contactarnos es escribirnos un mensaje a nuestro WhatsApp, también te invitamos a seguirnos en nuestras redes sociales, solo debes hacer clic en el ícono corrrespondiente.</p>
                            <div className='txt-c mt-3'>
                                <a href="https://wa.me/50662385373" className="fa fa-whatsapp"></a>
                                <a href="https://youtu.be/G59j_H5_GG0" className="fa fa-youtube"></a>
                                <a href="https://www.facebook.com/fincabuenavistaturrialba" className="fa fa-facebook"></a>
                                <a href="https://www.instagram.com/finca_buena_vista_/" className="fa fa-instagram"></a>
                            </div>
                        </Box>
                        {/* <img className="img-responsive border-rounded mt-5" alt="Mural Finca Buena Vista" src={muralImageUrl} /> */}
                    </Grid>
                </Grid>
            </Container>
            <Footer/>
        </div>
    );
};
