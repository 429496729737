import { Button, CircularProgress, Container, Paper, TextField } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { authStartLoging } from '../../actions/auth';
import { useForm } from '../../hooks/useForm';
import { showSwalAlert } from '../../shared/alert';
import { emptyFieldsMessage } from '../../shared/messages';


export const LoginForm = () => {

    const { isLoading } = useSelector(state => state.ui);
    const { isLoggedIn } = useSelector(state => state.auth);
    const history = useHistory();


    useEffect(() => {
        if (isLoggedIn) {
            history.push(`/user/dashboard`);
        }
    }, [isLoggedIn]);

    const dispatch = useDispatch();
    const [formValues, handleInputChange] = useForm({
        username: '',
        password: '',
    });

    const { username, password } = formValues;

    const handleLogin = (e) => {
        e.preventDefault();
        if (username && password) {
            dispatch(authStartLoging(username, password));
        } else {
            showEmptyErrorMessage();
        }
    };

    const showEmptyErrorMessage = () => {
        showSwalAlert(emptyFieldsMessage());
    };


    return (
        <Container className="txt-c mt-10 " maxWidth="sm">
            <Paper style={{ minHeight: '57vh' }} className="border-rounded" elevation={5} >
                <br />
                <h1 className="title-text mt-5">Iniciar sesión</h1>
                <form style={{ maxHeight: '100%' }} className="mt-3" onSubmit={handleLogin}>
                    <TextField
                        className="mt-10"
                        label="Usuario"
                        name="username"
                        value={username}
                        onChange={handleInputChange}
                        variant="outlined"
                        autoComplete="off"
                    />
                    <br />
                    <TextField
                        className="mt-10"
                        label="Contraseña"
                        name="password"
                        type="password"
                        value={password}
                        onChange={handleInputChange}
                        variant="outlined"
                        autoComplete="off"
                    />
                    <br />
                    <Button type="submit" className="bg-green2 mt-10" disabled={isLoading}>
                        {isLoading ? <CircularProgress /> : 'Iniciar sesión'}
                    </Button>
                    <div className="mt-5 subtitle-text">
                        <h5>
                            No tiene cuenta?
                            <Link to="/user/register"><p>Registrarse</p></Link>
                        </h5>
                    </div>

                </form>
                <br />
            </Paper>
        </Container>
    );
};
