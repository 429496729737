import React, { Children } from 'react';
import { AVAILABLE, UNAVAILABLE, BOOKED, ADMIN_OPTION_BOOKING_MANAGAMENT, ADMIN_OPTION_CONTENT_MANAGAMENT, PENDING, ADMIN_OPTION_TABLE_BOOKING_MANAGAMENT } from './constants';

export const customDayColor = (event, start, end, isSelected) => {
    let newStyle = {
        backgroundColor: "green",
        color: '#000000',
        borderRadius: "5px"
    };

    if (event.title === AVAILABLE) {
        newStyle.backgroundColor = "#a7c957";//GREEN
    } else if (event.title === PENDING) {
        newStyle.backgroundColor = "#EFAA71";//ORANGE
    } else if (event.title === UNAVAILABLE) {
        newStyle.backgroundColor = "lightgray";//YELLOW
    } else if (event.title === BOOKED) {
        newStyle.backgroundColor = "#bc4749";//RED
        newStyle.color = '#fff';
    }
    return {
        style: newStyle
    };
};

export const ColoredDateCellWrapper = ({ children, value }) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            backgroundColor: 'lightgray',
        },
    });

export const isInArray = (array, value) => {
    return (array.find(item => { return item === value }) || []).length > 0;
};

const convertPendingBookingArrayToStringArray = (bookingList = []) => {
    let bookingStringArray = [];
    bookingList.forEach((booking) => {
        booking.dates.forEach((date) => {
            if (booking.status === PENDING) {
                bookingStringArray = [...bookingStringArray, date];
            }
        });
    });

    return bookingStringArray;
}
const convertReservedBookingArrayToStringArray = (bookingList = []) => {
    let bookingStringArray = [];
    bookingList.forEach((booking) => {
        booking.dates.forEach((date) => {
            if (booking.status === BOOKED) {
                bookingStringArray = [...bookingStringArray, date];
            }
        });
    });

    return bookingStringArray;
};

export const getEventsList = (bookingList = [], unavailableDates = [], isAvailableMidWeek, pendingDates = []) => {
    let bookingStringArray = convertReservedBookingArrayToStringArray(bookingList);
    let pendingBookingStringArray = convertPendingBookingArrayToStringArray(bookingList);
    let until = new Date();
    until.setMonth(until.getMonth() + 12)

    let eventList = [];

    for (let now = new Date(); now < until; now.setDate(now.getDate() + 1)) {
        let day = now.getDate();
        let month = now.getMonth() + 1;
        let year = now.getFullYear();

        let fulldate = `${month}/${(day)}/${year}`;

        let event = {
            title: '',
            start: new Date(now),
            end: new Date(now),
        }

        if (isInArray(bookingStringArray, fulldate)) {
            event.title = BOOKED;

        } else if (isInArray(pendingBookingStringArray, fulldate)) {
            event.title = PENDING;
        } else if (isInArray(unavailableDates, fulldate)) {
            event.title = UNAVAILABLE;
        } else {

            if (!isAvailableMidWeek && now.getDay() > 0 && now.getDay() < 5) {
                event.title = UNAVAILABLE;
            } else {
                event.title = AVAILABLE;
            }
        }

        eventList = [...eventList, event];
    }
    return eventList;
};

export const getMessageDataByBookingTitle = (title) => {

    switch (title) {
        case AVAILABLE:
            return {
                title: 'Fecha disponible',
                description: '',
                isEmpty: true
            };

        case UNAVAILABLE:
            return {
                title: 'Fecha no disponible',
                description: 'Lo sentimos, esta fecha no esta disponible para reservas',
            };

        case BOOKED:
            return {
                title: 'Fecha reservada',
                description: 'Lo sentimos, esta fecha se encuentra reservada',
            };
        case PENDING:
            return {
                title: 'Fecha pendiente',
                description: 'Esta fechas se encuentra pendiente de confirmar. Puede consultar de nuevo en 24 horas',
            };

        default:
            return {
                title: '',
                description: '',
            };
    }
};

export const adminOptions = [
    // {
    //     id: ADMIN_OPTION_CONTENT_MANAGAMENT,
    //     title: 'Tabla de reservas',
    //     subtitle: 'Ingrese aqui para gestionar las reservas pendientes, aprobadas y rechazadas',
    //     info: '',
    //     imageUrl: 'https://res.cloudinary.com/djmhhp7hp/image/upload/v1677026408/finca-buena-vista/table_ozehty.png'
    // },
    {
        id: ADMIN_OPTION_BOOKING_MANAGAMENT,
        title: 'Gestionar reservas',
        subtitle: 'Ingrese aqui para registrar, modificar y eliminar reservas',
        info: '',
        imageUrl: 'https://res.cloudinary.com/djmhhp7hp/image/upload/v1632512778/finca-buena-vista/calendario_1_wr4eqp.png'
    },
    // {
    //     id: ADMIN_OPTION_CONTENT_MANAGAMENT,
    //     title: 'Gestionar contenido',
    //     subtitle: 'Ingrese aqui para modificar el contenido de la página',
    //     info: '',
    //     imageUrl: 'https://res.cloudinary.com/djmhhp7hp/image/upload/v1632512778/finca-buena-vista/contenido_xa9ikv.png'
    // }
];

export const clientOptions = () => (
    [
        {

        }
    ]
);

export const formatDate = (date) => {
    if (!date) return "";

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let fulldate = `${(day)}/${month}/${year}`;

    return fulldate;
}

export const getClassnameByBookingTitle = (title) => {
    switch (title) {
        case AVAILABLE:

            return "#a7c957";

        case UNAVAILABLE:

            return "#fafa5c";

        case BOOKED:

            return "#bc4749";

        default:
            return "";
    }
}

export const getDates = (startDate, endDate) => {
    let dates = []
    const theDate = new Date(startDate)
    while (theDate < endDate) {
        dates = [...dates, `${theDate.getMonth() + 1}/${theDate.getDate()}/${theDate.getFullYear()}`];
        theDate.setDate(theDate.getDate() + 1)
    }
    dates = [...dates, `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`];
    return dates
};

export const addUnavailableDaysToArray = (start, daysToPrepare, arr) => {
    let end = new Date(start)
    end.setDate(end.getDate() + daysToPrepare)
    start.setDate(start.getDate() - (daysToPrepare + 1))
    for (var dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(`${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`)
    }
    return arr;

};

export const removeUnavailableDaysToArray = (start, daysToPrepare, arr) => {
    let end = new Date(start)
    end.setDate(end.getDate() + daysToPrepare)
    start.setDate(start.getDate() - (daysToPrepare + 1))

    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {

        let day = dt.getDate();
        let month = dt.getMonth() + 1;
        let year = dt.getFullYear();

        let fulldate = `${month}/${day}/${year}`;

        const index = arr.indexOf(fulldate);

        if (index > -1) {
            arr.splice(index, 1);
        }
    }
    return arr;
};