import { Container } from '@material-ui/core'
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { lodgingDetailedInformationStartLoading } from '../../../actions/lodging';
import { Loading } from '../../ui/loading/Loading';
import { Calendar } from './information/Calendar';
import { Gallery } from './information/Gallery';
import { MainFeatures } from './information/MainFeatures';
import { Prices } from './information/Prices';
import { LodgingCard } from './LodgingCard';


export const LodgingDetailedInformation = ({ internal_name }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(lodgingDetailedInformationStartLoading(internal_name))
    }, [dispatch, internal_name]);

    const { activeLodging } = useSelector(state => state.lodging);
    const { isLoading } = useSelector(state => state.ui);

    if (isLoading) {
        return <Loading/>
    } else {
        return (
            <div className="mt-10">
                <Container maxWidth="lg">
                    <LodgingCard data={activeLodging} />
                    <MainFeatures />
                    <Calendar />
                    <Prices />
                    <Gallery />
                </Container>
            </div>
        );
    }


};