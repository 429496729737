import { BOOKED, PENDING } from "../shared/constants";
import { types } from "../types/types";

const initialState = {
    basicLodgingList: [],
    activeLodging: {
        internalName: '',
        mainServices: [],
        services: [],
        prices: [],
        termsAndConditions: [],
        bedroomImages: [{ photo: '' }],
        indoorImages: [{ photo: '' }],
        outdoorImages: [{ photo: '' }],
        bookings: [],
        unavailableDates: [],
        isAvailableMidWeek: false,
        daysToPrepare: 0
    },
    activeEvent: {},
    activeBooking: {
        title: '',
        client: '',
        start: new Date(),
        end: new Date(),
        adults: 2,
        kids: 2,
        totalAmount: 0,
        deposit: 0,
        sendEmail: false
    }
};

export const lodgingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.lodgingBasicListLoaded:
            return {
                ...state,
                basicLodgingList: action.payload
            }

        case types.lodginDetailedInformationLoaded:
            return {
                ...state,
                activeLodging: action.payload
            }

        case types.lodgingClearActive:
            return {
                ...state,
                activeLodging: initialState.activeLodging
            }
        case types.lodgingSetActiveEvent:
            return {
                ...state,
                activeEvent: action.payload
            }

        case types.lodgingClearActiveEvent:
            return {
                ...state,
                activeEvent: {}
            }
        case types.lodgingSetActiveBooking:
            let { title, start } = action.payload;
            const { activeLodging: { bookings } } = state;

            let activeBooking = {
                title,
                client: '',
                start: new Date(start),
                end: new Date(start),
                adults: 2,
                kids: 2,
                totalAmount: 0,
                deposit: 0,
                sendEmail: false
            };
            if (title === BOOKED || title === PENDING) {
                let startDate = new Date(start)
                let month = startDate.getMonth() + 1;
                let day = startDate.getDate();
                let year = startDate.getFullYear();

                let fullDate = `${month}/${(day)}/${year}`;

                bookings.forEach((booking) => {
                    booking.dates.forEach((date) => {
                        if (fullDate === date) {
                            activeBooking = booking;
                            return;
                        }
                    })
                });
                const { dates } = activeBooking;
                activeBooking.start = new Date(dates[0]);
                activeBooking.end = new Date(dates[dates.length - 1]);
                activeBooking.title = title;
            }
            return {
                ...state,
                activeBooking
            }


        case types.lodgingClearActiveBooking:
            return {
                ...state,
                activeBooking: initialState.activeBooking
            }

        case types.lodgingUpdateClientActiveBooking:
            return {
                ...state,
                activeBooking: {
                    ...state.activeBooking,
                    client: action.payload
                }
            }

        case types.lodgingUpdateEndActiveBooking:
            return {
                ...state,
                activeBooking: {
                    ...state.activeBooking,
                    end: action.payload
                }
            }

        case types.lodgingUpdateTotalAmountActiveBooking:
            return {
                ...state,
                activeBooking: {
                    ...state.activeBooking,
                    totalAmount: action.payload
                }
            }

        case types.lodgingUpdateDepositActiveBooking:
            return {
                ...state,
                activeBooking: {
                    ...state.activeBooking,
                    deposit: action.payload
                }
            }

        case types.lodgingUpdateAdultsActiveBooking:
            return {
                ...state,
                activeBooking: {
                    ...state.activeBooking,
                    adults: action.payload
                }
            }
        case types.lodgingUpdateSendEmailActiveBooking:
            return {
                ...state,
                activeBooking: {
                    ...state.activeBooking,
                    sendEmail: action.payload
                }
            }

        case types.lodgingUpdateKidsActiveBooking:
            return {
                ...state,
                activeBooking: {
                    ...state.activeBooking,
                    kids: action.payload
                }
            }
        default:

            return state;
    }
}