
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { ColoredDateCellWrapper, customDayColor, getEventsList, getMessageDataByBookingTitle } from '../../../shared/util';

import { PENDING, UNAVAILABLE } from '../../../shared/constants';

import moment from 'moment';
import 'moment/locale/es';
import { AdminCalendarModal } from './AdminCalendarModal';
import { lodgingSetActiveBooking } from '../../../actions/lodging';
import CustomToolbar from '../../public/Lodging/information/CustomToolbar';
moment.locale('es');
const localizer = momentLocalizer(moment);

export const AdminCalendar = () => {

    const dispatch = useDispatch();

    const handleClickedDay = (event) => {
        if (event.title !== UNAVAILABLE) {
            dispatch(lodgingSetActiveBooking(event));
        }
    };
    const { activeLodging: { bookings, unavailableDates, isAvailableMidWeek } } = useSelector(state => state.lodging);
    const events = getEventsList(bookings, unavailableDates, isAvailableMidWeek);

    return (
        <div>
            <AdminCalendarModal />
            <BigCalendar
                className="border-rounded"
                min={new Date()}
                localizer={localizer}
                view={'month'}
                onSelectEvent={(event, e) => handleClickedDay(event)}
                components={{
                    toolbar: CustomToolbar,
                    dateCellWrapper: ColoredDateCellWrapper
                }}
                onView={() => { }}
                eventPropGetter={
                    (event, start, end, isSelected) => customDayColor(event, start, end, isSelected)
                }
                events={events}
            />
        </div>
    );
};