export const baseURL = process.env.REACT_APP_API;
export const AVAILABLE = 'DISPONIBLE';
export const UNAVAILABLE = 'NO DISPONIBLE';
export const PENDING = 'PENDIENTE';
export const BOOKED = 'RESERVADO';
export const REJECTED = 'RECHAZADO';
export const USER_CLIENT = 'CLIENT';
export const USER_ADMIN = 'ADMIN';
export const ADMIN_OPTION_BOOKING_MANAGAMENT = 'BOOKING_MANAGEMENT';
export const ADMIN_OPTION_CONTENT_MANAGAMENT = 'CONTENT_MANAGEMENT';
export const ADMIN_OPTION_TABLE_BOOKING_MANAGAMENT = 'TABLE_BOOKING_MANAGEMENT';
export const DEFAULT_PASSWORD = '1234';

