import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const LocationNearbyPlaceModal = ({ lodging }) => {
    const { name, description, imageUrl } = lodging;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button onClick={handleClickOpen()} className="bg-red">Ver detalles</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{name}</DialogTitle>
                <DialogContent dividers className="subtitle-text">
                    <div>
                        <img className="img-responsive border-rounded" src={imageUrl} />
                    </div>
                    {description}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="bg-green2">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
