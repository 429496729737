import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useSelector, useDispatch } from 'react-redux';
import { hideAdminManagementModal } from '../../../actions/ui';
import { ADMIN_OPTION_BOOKING_MANAGAMENT, ADMIN_OPTION_CONTENT_MANAGAMENT } from '../../../shared/constants';
import { BookingManagement } from './BookingManagement';
import { ContentManagement } from './ContentManagement';
import { lodgingClearActiveBooking } from '../../../actions/lodging';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ManagementModal = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { showAdminManagementModal, adminSelectedOption } = useSelector(state => state.ui);

    const handleClose = () => {
        dispatch(hideAdminManagementModal());
        dispatch(lodgingClearActiveBooking());
    };

    const showBookingManagement = () => {
        return adminSelectedOption === ADMIN_OPTION_BOOKING_MANAGAMENT;
    }

    const showContentManagement = () => {
        return adminSelectedOption === ADMIN_OPTION_CONTENT_MANAGAMENT;
    }


    return (
        <div>
            <Dialog className="bg-white" fullScreen open={showAdminManagementModal} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={`${classes.appBar} bg-red`}>
                    <Toolbar>
                        <IconButton edge="start" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {
                                showBookingManagement() && ('Gestionar Reservas')
                            }
                        </Typography>
                        <Typography variant="h6" className={classes.title}>
                            {
                                showContentManagement() && ('Tabla Reservas')
                            }
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="">
                    {
                        showBookingManagement() && <BookingManagement />
                    }
                </div>
                <div className="">
                    {
                        showContentManagement() && <ContentManagement />
                    }
                </div>
            </Dialog>
        </div>
    );
};
