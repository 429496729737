import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux';
import { FeaturesModal } from './FeaturesModal';

export const MainFeatures = () => {
    const mainFeatures = [1, 2, 3, 4];

    const { activeLodging } = useSelector(state => state.lodging);
    const { mainServices } = activeLodging;
    return (
        <div>
            <h1 className="title-text">Principales características</h1>
            <Grid container spacing={5}>
                {mainServices.map(service => {
                    return (
                        <Grid className="mt-3" key={service.name} item lg={3} xs={6} sm={6}>
                            <Paper className="bg-white" elevation={3} >
                                <img className="img-responsive border-rounded" src={service.imageUrl} />
                                <div className="txt-c">
                                    <h3 className="subtitle-text mt-2">{service.name}</h3>
                                </div>
                            </Paper>
                        </Grid>
                    )
                })}
                <Grid item lg={12} xs={12} sm={12} className="txt-c">
                    <FeaturesModal />
                </Grid>
            </Grid>
        </div>
    );
};
