import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

export const LocationBasicInformation = () => {

    const { information: { muralCaption, muralImageUrl } } = useSelector(state => state.information);

    return (
        <Container className='mt-10' maxWidth="lg">
            <Grid className="txt-l" spacing={6} container>
                <Grid item md={12} lg={12} xs={12} sm={12} >
                    <h1 className="title-text txt-l">San Antonio de Santa Cruz, Turrialba</h1>
                    {/* <div className='mt-2'>
                        <img className="img-responsive border-rounded" alt="Mural Finca Buena Vista" src={muralImageUrl} />
                    </div>
                    <p className="info-text txt-r mt-2">Mural hecho por Miguel Villalobos Quiros <br />+506 84104584</p> */}
                    <h3 className="subtitle-text txt-justify mt-5">{muralCaption}</h3>
                </Grid>
            </Grid>
        </Container>
    );
};
