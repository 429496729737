import { Container, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { LocationCard } from './LocationCard';

export const LocationNearbyPlaces = () => {
    
    const { nearbyPlacesList } = useSelector(state => state.information);


    const columnSize = 12 / nearbyPlacesList.length;
    const { information: { locationMapUrl } } = useSelector(state => state.information);
    return (
        <Container className="mt-20" maxWidth="lg">
            <h1 className="title-text txt-l">Explora cerca de Finca Buena Vista</h1>
            <Grid className="mt-2 txt-c" spacing={6} container>
                {nearbyPlacesList.map((lodging, index) => (<LocationCard key={lodging.id} data={lodging} columnSize={columnSize} />))}
            </Grid>
            <h1 className="mt-20 title-text txt-l">Ubicación</h1>
            <div className="container-iframe">
                <iframe
                    title="Mapa"
                    className="mt-5 responsive-iframe border-rounded"
                    src={locationMapUrl}
                    frameBorder="0"
                    allowFullScreen={false}
                    aria-hidden="false"
                > </iframe></div>
        </Container>
    );
};
