import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector } from 'react-redux';
import { HideModal } from '../../../../actions/ui';
import { useDispatch } from 'react-redux';
import { lodgingClearActiveEvent } from '../../../../actions/lodging';
import { BookingForm } from './BookingForm';

export const CalendarModal = () => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(lodgingClearActiveEvent());
        dispatch(HideModal());
    };
    const { showCalendarModal, bookingModalData } = useSelector(state => state.ui);
    const { title, description, isEmpty } = bookingModalData;

    return (
        <div>
            <Dialog
                open={showCalendarModal && !isEmpty}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {
                        (!isEmpty)
                            ?
                            <DialogContentText id="alert-dialog-description">
                                {description}
                            </DialogContentText>
                            :
                            <BookingForm />
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

