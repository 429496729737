import { authService } from "../helpers/api/auth";
import { showSwalAlert } from "../shared/alert";
import { DEFAULT_PASSWORD, USER_CLIENT } from "../shared/constants";
import { basicErrorMessage, loginErrorMessage, createClientSuccessMessage, updateClientSuccessMessage } from "../shared/messages";
import { types } from "../types/types";
import { informationGetClientList } from "./information";
import { loadingStart, loadingStop } from "./ui";

export const authStartLoging = (username, password) => {
    return async (dispatch) => {
        try {
            dispatch(loadingStart());
            const response = await authService.login(username, password);
            if (response) {
                const { data } = response;
                dispatch(authLogin(data));
            } else {
                showSwalAlert(loginErrorMessage());
            }
            dispatch(loadingStop());
        } catch (error) {
            console.error("actions.auth.authStartLoging");
            showSwalAlert(basicErrorMessage());
            dispatch(loadingStop());
        }
    };
};

export const authCreateClient = (client) => {
    return async (dispatch) => {
        client.username = client.phone;
        client.password = DEFAULT_PASSWORD
        client.userType = USER_CLIENT;

        try {
            dispatch(loadingStart());
            const response = await authService.createClient(client);
            if (response) {
                showSwalAlert(createClientSuccessMessage());
                dispatch(informationGetClientList());
            } else {
                showSwalAlert(basicErrorMessage());
            }
            dispatch(loadingStop());
        } catch (error) {
            console.error("actions.auth.authCreateClient");
            showSwalAlert(basicErrorMessage());
            dispatch(loadingStop());
        }
    };
};

export const authUpdateClient = (updatedClient) => {
    return async (dispatch) => {
        try {
            dispatch(loadingStart());
            const response = await authService.updateClient(updatedClient);
            if (response) {
                showSwalAlert(updateClientSuccessMessage());
                dispatch(informationGetClientList());
            } else {
                showSwalAlert(basicErrorMessage());
            }
            dispatch(loadingStop());
        } catch (error) {
            console.error("actions.auth.authUpdateClient");
            showSwalAlert(basicErrorMessage());
            dispatch(loadingStop());
        }
    };
};


export const authLogin = (user) => ({
    type: types.authLogin,
    payload: user
})

