import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Tooltip from '@mui/material/Tooltip';


export default class CustomToolbar extends Toolbar {

    constructor(props) {
        super(props)

        // Set initial state 
        this.state = {
            showTooltip: false
        }

        // Binding this keyword 
        this.updateState = this.updateState.bind(this)
        setInterval(() => {
            this.updateState();
        }, 3000);
    }

    updateState() {
        this.setState({
            showTooltip: !this.state.showTooltip
        })
    }
    navigate = action => {
        this.props.onNavigate(action)
    };



    render() {
        return (
            <div className="justify-content-end bg-green2">
                <br />
                <center>
                    <h1 className="mb-2" > Reservas {this.props.label}</h1>
                    <ArrowBackIcon style={{ fontSize: 50, color: this.state.showTooltip ? '#bc4749' : 'white' }} className="pointer" onClick={() => this.navigate('PREV')} />
                    <ArrowForward style={{ fontSize: 50, color: !this.state.showTooltip ? '#bc4749' : 'white' }} className="pointer" onClick={() => this.navigate('NEXT')} />
                </center>
            </div>
        );
    };
};
