import { lodgingService } from "../helpers/api/lodging";
import { showSwalAlert } from "../shared/alert";
import { basicErrorMessage, createBookingSuccessMessage, removeBookingSuccessMessage } from "../shared/messages";
import { types } from "../types/types";
import { loadingStart, loadingStop } from "./ui";

export const lodgingBasicListStartLoading = () => {
    return async (dispatch) => {
        try {
            dispatch(loadingStart());
            const response = await lodgingService.getBasicLodgingList();
            const { data } = response;
            const lodgingList = data.list;
            if (lodgingList.length > 0) {
                dispatch(lodgingBasicListLoaded(lodgingList));
            }
            dispatch(loadingStop());
        } catch (error) {
            console.error("actions.lodging.lodgingBasicStartLoading");
        }
    };
};

export const lodgingDetailedInformationStartLoading = (internalName) => {
    return async (dispatch) => {
        try {
            dispatch(loadingStart());
            const response = await lodgingService.getDetailedLodgingInformation(internalName);
            const { data } = response;
            const lodgingInformation = data;
            if (lodgingInformation) {
                dispatch(lodgingDetailedInformationLoaded(lodgingInformation));
            }
            dispatch(loadingStop());
        } catch (error) {
            console.error("actions.lodging.lodgingDetailedInformationStartLoading");
        }
    };
};

export const lodgingAddBookingAndUpdateUnavailableDates = (lodgingId, newBooking, unavailableDates, internalName) => {
    return async (dispatch) => {
        try {
            dispatch(loadingStart());
            const response = await lodgingService.addBookingAndUpdateUnavailableDates(lodgingId, newBooking, unavailableDates);
            if (response) {
                showSwalAlert(createBookingSuccessMessage());
                dispatch(lodgingDetailedInformationStartLoading(internalName));
                dispatch(lodgingClearActiveBooking());
            } else {
                showSwalAlert(basicErrorMessage());
            }
            dispatch(loadingStop());
        } catch (error) {
            console.error("actions.lodging.lodgingAddBookingAndUpdateUnavailableDates");
        }
    };
};

export const lodgingRemoveBookingAndClearUnavailableDates = (lodgingId, bookings, unavailableDates, internalName) => {
    return async (dispatch) => {
        try {
            dispatch(loadingStart());
            const response = await lodgingService.removeBookingAndClearUnavailableDates(lodgingId, bookings, unavailableDates);
            if (response) {
                showSwalAlert(removeBookingSuccessMessage());
                dispatch(lodgingDetailedInformationStartLoading(internalName));
                dispatch(lodgingClearActiveBooking());
            } else {
                showSwalAlert(basicErrorMessage());
            }
            dispatch(loadingStop());
        } catch (error) {
            console.error("actions.lodging.lodgingRemoveBookingAndClearUnavailableDates");
        }
    };
};

const lodgingBasicListLoaded = (lodgingList) => ({
    type: types.lodgingBasicListLoaded,
    payload: lodgingList
});

const lodgingDetailedInformationLoaded = (lodgingInformation) => ({
    type: types.lodginDetailedInformationLoaded,
    payload: lodgingInformation
});

export const lodgingClearActive = () => ({
    type: types.lodgingClearActive
});

export const lodgingSetActiveEvent = (event) => ({
    type: types.lodgingSetActiveEvent,
    payload: event
});

export const lodgingClearActiveEvent = () => ({
    type: types.lodgingClearActiveEvent
})

export const lodgingSetActiveBooking = (event) => ({
    type: types.lodgingSetActiveBooking,
    payload: event
});

export const lodgingClearActiveBooking = () => ({
    type: types.lodgingClearActiveBooking
});

export const lodgingUpdateClientActiveBooking = (data) => ({
    type: types.lodgingUpdateClientActiveBooking,
    payload: data
});

export const lodgingUpdateEndActiveBooking = (data) => ({
    type: types.lodgingUpdateEndActiveBooking,
    payload: data
});

export const lodgingUpdateTotalAmountActiveBooking = (data) => ({
    type: types.lodgingUpdateTotalAmountActiveBooking,
    payload: data
});

export const lodgingUpdateDepositActiveBooking = (data) => ({
    type: types.lodgingUpdateDepositActiveBooking,
    payload: data
});

export const lodgingUpdateAdultsActiveBooking = (data) => ({
    type: types.lodgingUpdateAdultsActiveBooking,
    payload: data
});

export const lodgingUpdateKidsActiveBooking = (data) => ({
    type: types.lodgingUpdateKidsActiveBooking,
    payload: data
});

export const lodgingUpdateSendEmailActiveBooking = (data) => ({
    type: types.lodgingUpdateSendEmailActiveBooking,
    payload: data
});