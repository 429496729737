import { Divider, Grid, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export const Prices = () => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CRC',
        minimumFractionDigits: 0
    });
    const classes = useStyles();

    const { activeLodging } = useSelector(state => state.lodging);
    const { prices, termsAndConditions } = activeLodging;

    return (
        <div  className="mt-10">
            <h1 className="title-text">Precios</h1>
            <Grid container spacing={5} className="mt-3">
                <Grid item md={7} sm={7} xs={12}>
                    <TableContainer className="bg-white border-rounded">
                        <Table size="medium" >
                            <TableHead>
                                <TableRow className="bg-green3">
                                    <TableCell><h3 className="subtitle-text txt-c mt-2">Cantidad de personas</h3></TableCell>
                                    <TableCell><h3 className="subtitle-text txt-c mt-2">Precio</h3></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="bg-green3">
                                {prices.map((price) => (
                                    <TableRow key={price.quantity}>
                                        <TableCell component="th" scope="row">
                                            <h3 className="subtitle-text txt-c mt-2">{price.quantity}</h3>
                                        </TableCell>
                                        <TableCell><h3 className="subtitle-text txt-c mt-2">{formatter.format(price.price)}</h3></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item md={5} sm={5} xs={12} >
                    <List component="nav" className={`${classes.root} bg-green3 border-rounded`}>
                        {
                            termsAndConditions.map((condition) => (
                                <div key={condition}>
                                    <ListItem className="txt-l">
                                        <ListItemText>
                                            {condition}
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                </div>
                            ))
                        }
                    </List>
                </Grid>
            </Grid>
        </div>
    );
};
