import axiosInstance from "./axiosInstance";

const login = async (username, password) => {
    let response;

    let request = {
        username,
        password
    }
    try {
        response = await axiosInstance.post(`auth/login`, request);
    } catch (error) {
        console.error("api.auth.login");
    }
    return response;
};


const getClients = async () => {
    let response;

    try {
        response = await axiosInstance.get(`auth/getClients`);
    } catch (error) {
        console.error("api.auth.getClients");
    }
    return response;
};

const createClient = async (client) => {
    let response;

    try {
        response = await axiosInstance.post(`auth/client/create`, client);
    } catch (error) {
        console.error("api.auth.create");
    }
    return response;
};

const updateClient = async (client) => {
    let response;

    try {
        response = await axiosInstance.post(`auth/client/update`, client);
    } catch (error) {
        console.error("api.auth.update");
    }
    return response;
};


export const authService = {
    login,
    getClients,
    createClient,
    updateClient
}