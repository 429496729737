import React, { useEffect, useRef } from 'react';
import { Navbar } from '../ui/navbar/Navbar';
import { Footer } from '../ui/footer/Footer';
import { LocationBasicInformation } from './Location/LocationBasicInformation';
import { LocationNearbyPlaces } from './Location/LocationNearbyPlaces';
import { useDispatch } from 'react-redux';
import { lodgingClearActive } from '../../actions/lodging';
import InformationLanding from './Information/InformationLanding'; 

export const MainScreen = () => {

    const dispatch = useDispatch();

    const contentRef = useRef(null);
    const executeScroll = () => contentRef.current.scrollIntoView();

    useEffect(() => {
        dispatch(lodgingClearActive());
    }, [dispatch]);

    return (
        <div className="main__overflow-hidden bg-white">
            <Navbar />
            {/* <div className="main__img-wrapper">
                <InformationJumbotron />
            </div>
            <DownArrow executeScroll={executeScroll} />
            <InformationBasic reference={contentRef} /> */}
            <InformationLanding executeScroll={executeScroll}/>
            <LocationBasicInformation />
            <LocationNearbyPlaces />
            <Footer reference={contentRef} />
        </div>
    );
};