import { types } from "../types/types";

const initialState = {
    isLoading: false,
    showCalendarModal: false,
    bookingModalData: {
        title: '',
        description: ''
    },
    showAdminManagementModal: false,
    adminSelectedOption: ''
};

export const uiReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.uiLoadingStart:
            return {
                ...state,
                isLoading: true
            };

        case types.uiLoadingStop:
            return {
                ...state,
                isLoading: false
            };
        case types.uiModalShow:
            return {
                ...state,
                showCalendarModal: true
            };

        case types.uiModalHide:
            return {
                ...state,
                showCalendarModal: false
            };

        case types.uiSetModalData:
            return {
                ...state,
                bookingModalData: action.payload
            };

        case types.uiClearModalData:
            return {
                ...state,
                bookingModalData: action.payload
            };

        case types.uiAdminManagementModalShow:
            return {
                ...state,
                showAdminManagementModal: true
            };

        case types.uiAdminManagementModalHide:
            return {
                ...state,
                showAdminManagementModal: false,
                adminSelectedOption: ''
            };

        case types.uiSetSelectedAdminOption:
            return {
                ...state,
                adminSelectedOption: action.payload
            };

        default:

            return state;
    };
};