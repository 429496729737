import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authGetClientList } from '../../actions/auth';
import { USER_ADMIN, USER_CLIENT } from '../../shared/constants';
import { Footer } from '../ui/footer/Footer';
import { Navbar } from '../ui/navbar/Navbar';
import ScrollToTop from '../ui/scroll/ScrollToTop';
import { AdminDashboardOptions } from './Admin/AdminDashboardOptions';
import { ClientDashboardOptions } from './Client/ClientDashboardOptions';


export const DashboardScreen = () => {
    const dispatch = useDispatch();
    const { user: { userType } } = useSelector(state => state.auth);
    useEffect(() => {
        //TODO: obtener token
        //TODO: volver a cargar la info
    }, [dispatch]);

    return (
        <div className="main__overflow-hidden bg-white">
            <ScrollToTop />
            <Navbar />
            {
                (userType === USER_ADMIN || true)
                    ?
                    <AdminDashboardOptions />
                    :
                    <ClientDashboardOptions />
            }
            <Footer />
        </div>
    );
};
