import { authService } from "../helpers/api/auth";
import { informationService } from "../helpers/api/information";
import { types } from "../types/types";

export const informationStartLoading = () => {
    return async (dispatch) => {
        try {
            const informationResponse = await informationService.getInformation();
            const nearbyPlacesResponse = await informationService.getNearbyPlacesList();

            const { data: informationData } = informationResponse;
            const { data: nearbyPlacesData } = nearbyPlacesResponse;

            const information = informationData.information;

            const nearbyPlaces = nearbyPlacesData.nearbyPlaceList;

            if (information && nearbyPlaces) {
                dispatch(informationLoaded(information, nearbyPlaces));
            }

        } catch (error) {
            console.error("actions.information.informationStartLoading");
        }
    };
};

const informationLoaded = (information, nearbyPlaces) => ({
    type: types.informationLoaded,
    payload: {
        information,
        nearbyPlaces
    }
})

export const informationGetClientList = () => {
    return async (dispatch) => {
        try {
            const response = await authService.getClients();
            if (response) {
                const { data } = response;
                dispatch(informationSetClientList(data));
            }
        } catch (error) {
            console.error("actions.auth.authgetClientList");
        }
    };
};
export const informationSetClientList = (data) => ({
    type: types.authSetClients,
    payload: data
})
