import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    position: "absolute",
    right: 0
  },
  buttonBar: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    margin: "10px",
    paddingLeft: "16px",
    right: 0,
    position: "relative",
    width: "100%",
    background: "transparent"
  }
});

const AppBarCollapse = props => (
  <div className={`${props.classes.root}`}>
    <ButtonAppBarCollapse >

    </ButtonAppBarCollapse>
    <div className={`${props.classes.buttonBar} `} id="appbar-collapse">

    </div>
  </div>
);

export default withStyles(styles)(AppBarCollapse);
