import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { informationStartLoading } from '../actions/information';
import { lodgingBasicListStartLoading } from '../actions/lodging';
import { LoginScreen } from '../components/auth/LoginScreen';
import { DashboardScreen } from '../components/private/DashboardScreen';
import { ContactScreen } from '../components/public/ContactScreen';
import { LodgingDetailScreen } from '../components/public/LodgingDetailScreen';
import { MainScreen } from '../components/public/MainScreen';

export const AppRouter = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(informationStartLoading());
        dispatch(lodgingBasicListStartLoading());
    }, [dispatch]);

    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path="/"
                    component={MainScreen}
                />
                <Route
                    exact
                    path="/info/contact"
                    component={ContactScreen}
                />
                <Route
                    exact
                    path="/user/dashboard"
                    component={DashboardScreen}
                />
                <Route
                    exact
                    path="/:internal_name"
                    component={LodgingDetailScreen}
                />
                <Route
                    exact
                    path="/user/login/access/private"
                    component={LoginScreen}
                />
                <Redirect to="/" />
            </Switch>
        </Router>
    )
};