import { Grid } from '@material-ui/core';
import React from 'react';
import { LocationNearbyPlaceModal } from './LocationNearbyPlaceModal';

export const LocationCard = ({ data, columnSize }) => {

    const { name, imageUrl } = data;

    return (
        <Grid item md={columnSize} lg={columnSize} xs={12} sm={columnSize} >
            <div>
                <img className="img-responsive border-rounded" alt={name} src={imageUrl} />
            </div>
            <h3 className="subtitle-text mt-2">{name}</h3>
            <div className="mt-3 txt-c">
                <LocationNearbyPlaceModal lodging={data} />
            </div>
        </Grid>
    );
};
