import React, { useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Paper, TextField } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AVAILABLE, BOOKED, PENDING } from '../../../shared/constants';
import { formatDate, getDates, addUnavailableDaysToArray, removeUnavailableDaysToArray } from '../../../shared/util';
import DateFnsUtils from '@date-io/date-fns';
import { lodgingAddBookingAndUpdateUnavailableDates, lodgingClearActiveBooking, lodgingDetailedInformationStartLoading, lodgingRemoveBookingAndClearUnavailableDates, lodgingUpdateAdultsActiveBooking, lodgingUpdateClientActiveBooking, lodgingUpdateDepositActiveBooking, lodgingUpdateEndActiveBooking, lodgingUpdateKidsActiveBooking, lodgingUpdateSendEmailActiveBooking, lodgingUpdateTotalAmountActiveBooking } from '../../../actions/lodging';
import { authCreateClient, authUpdateClient } from "../../../actions/auth";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useForm } from '../../../hooks/useForm';
import { showSwalAlert } from '../../../shared/alert';
import { emptyFieldsMessage } from '../../../shared/messages';
import Autocomplete from '@mui/material/Autocomplete';

import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


export const BookingForm = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedClientToUpdate, setSelectedClientToUpdate] = React.useState(null);
    const [formValues, handleInputChange, reset] = useForm({
        fullName: '',
        phone: '',
        email: '',
    });
    const { fullName, phone, email } = formValues;


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { basicLodgingList, activeLodging: { id, mainImageUrl, internalName, prices, maxCapacity, minCapacity, unavailableDates, daysToPrepare, bookings }, activeBooking } = useSelector(state => state.lodging);
    const { clients } = useSelector(state => state.auth);
    const { client, title, start, end, adults, kids, totalAmount, deposit, sendEmail } = activeBooking;
    const formatStartDate = formatDate(start);
    const formatEndDate = (end) ? (new Date(end)) : new Date();

    const handleBooking = (event) => {
        event.preventDefault();
        if (isValidBookingObject()) {
            let bookingToRegister = { ...activeBooking };
            bookingToRegister.dates = getDates(bookingToRegister.start, bookingToRegister.end);
            let unavailableDatesToRegister = [...unavailableDates];
            let lodgingId = id;
            addUnavailableDaysToArray(end, daysToPrepare, unavailableDatesToRegister);
            delete bookingToRegister.start;
            delete bookingToRegister.end;
            delete bookingToRegister.title;
            dispatch(lodgingAddBookingAndUpdateUnavailableDates(lodgingId, bookingToRegister, unavailableDatesToRegister, internalName));

        } else {
            showSwalAlert(emptyFieldsMessage());
        }
    };

    const handleUpdateBooking = () => {

    };

    const handleDeleteBooking = () => {
        Swal.fire({
            title: '¿Seguro que desea eliminar la reserva?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let lodgingId = id;
                let clearedBookings = [...bookings];
                let unavailableDatesToClear = [...unavailableDates];
                let startD = new Date(start);
                let month = startD.getMonth() + 1;
                let day = startD.getDate();
                let year = startD.getFullYear();

                let fullDate = `${month}/${(day)}/${year}`;

                let count = 0
                let bookingIndex = 0
                clearedBookings.forEach((booking) => {

                    booking.dates.forEach((date,) => {
                        if (fullDate === date) {
                            bookingIndex = count
                        }
                    });
                    count++
                });
                clearedBookings.splice(bookingIndex, 1)
                removeUnavailableDaysToArray(end, daysToPrepare, unavailableDatesToClear);
                dispatch(lodgingRemoveBookingAndClearUnavailableDates(lodgingId, clearedBookings, unavailableDatesToClear, internalName));
            }
        })


    }

    const isValidBookingObject = () => {
        const { client, start, end, adults, kids, totalAmount } = activeBooking;

        if (client.length > 0 && start && end && adults > 0 && kids >= 0 && adults <= maxCapacity && totalAmount > 0) {
            return true;
        } else {
            return false;
        }
    }

    const handleCreateUserSubmit = (event) => {
        event.preventDefault();
        if (fullName && phone && email) {
            dispatch(authCreateClient(formValues));
            reset({ fullName: '', phone: '', email: '', });
        } else {
            showSwalAlert(emptyFieldsMessage());
        }
    };

    const handleLodgingChange = (lodging) => {
        dispatch(lodgingDetailedInformationStartLoading(lodging.target.value));
        dispatch(lodgingClearActiveBooking());
    };

    const handleBookingChange = ({ target }, clientData = []) => {
        const { name, value } = target;
        switch (name) {
            case "client":
                dispatch(lodgingUpdateClientActiveBooking(value));
                break;

            case "totalAmount":
                dispatch(lodgingUpdateTotalAmountActiveBooking(value));
                break;

            case "deposit":
                dispatch(lodgingUpdateDepositActiveBooking(value));
                break;

            case "adults":
                dispatch(lodgingUpdateAdultsActiveBooking(value));
                break;

            case "kids":
                dispatch(lodgingUpdateKidsActiveBooking(value));
                break;
            case "sendEmail":
                dispatch(lodgingUpdateSendEmailActiveBooking(target.checked));
                break;
            default:
                const clientPhoneNumber = clientData ? clientData.split('-')[1] : 'WRONG_PHONE_NUMBER';
                const selectedClient = clients.find(client => client.phone == clientPhoneNumber) || { id: null };
                if (selectedClient.id) {
                    dispatch(lodgingUpdateClientActiveBooking(selectedClient.id));
                }
                break;
        };
    };

    const handleBookingEndDateChange = (date) => {
        dispatch(lodgingUpdateEndActiveBooking(date));
    };

    const selectedClientData = () => {
        const clientId = client || '';
        const clientData = clients.find(c => c.id == clientId);

        return clientData ? `${clientData.fullName}-${clientData.phone}` : '';
    }

    const getGridSize = () => (title === AVAILABLE ? 3 : 6);

    const searchUser = () => {
        const selectedClient = clients.find(client => client.phone == phone);
        if (phone && selectedClient) {
            setSelectedClientToUpdate(selectedClient);
            reset({ fullName: selectedClient.fullName, phone: selectedClient.phone, email: selectedClient.email, });
        } else {
            resetClient();
        }
    };

    const resetClient = () => {
        setSelectedClientToUpdate(null);
        reset({ fullName: '', phone: '', email: '', });
    }

    const updateClient = () => {
        let selectedClientID = selectedClientToUpdate.id;
        const updatedClient = {
            id: selectedClientID,
            fullName,
            email,
            phone
        };
        dispatch(authUpdateClient(updatedClient));
    };

    return (
        <Paper className="border-rounded" elevation={10} >
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={`${classes.paper} border-rounded`}>
                        <form onSubmit={handleCreateUserSubmit}>
                            <Grid container spacing={2} >
                                <Grid className="txt-c mb-4" item md={12} lg={12} xs={12} sm={12}>
                                    <h3 className="title-text">{`${selectedClientToUpdate === null ? 'Registrar' : 'Actualizar'} cliente`}</h3>
                                </Grid>
                                <Grid item md={6} lg={6} xs={6} sm={6}>
                                    <InputLabel htmlFor="select">{selectedClientToUpdate === null ? 'Nombre Completo' : `Nombre Completo: ${selectedClientToUpdate.fullName}`}</InputLabel>
                                    <TextField variant="filled" onChange={handleInputChange} type="text" value={fullName} name="fullName" fullWidth />
                                </Grid>
                                <Grid item md={6} lg={6} xs={6} sm={6}>
                                    <InputLabel htmlFor="select">{selectedClientToUpdate === null ? 'Número de WhatsApp' : `Número de WhatsApp: ${selectedClientToUpdate.phone}`}</InputLabel>
                                    <TextField variant="filled" onChange={handleInputChange} type="text" value={phone} name="phone" fullWidth />
                                </Grid>
                                <Grid item md={12} lg={12} xs={12} sm={12}>
                                    <InputLabel htmlFor="select">{selectedClientToUpdate === null ? 'Correo' : `Correo: ${selectedClientToUpdate.phone}`}</InputLabel>
                                    <TextField variant="filled" onChange={handleInputChange} type="email" value={email} name="email" fullWidth />
                                </Grid>
                                {
                                    (phone) &&
                                    <Grid item md={4} lg={4} xs={4} sm={4}>
                                        <div className="txt-c">
                                            <Button onClick={() => resetClient()} className="bg-green1 mt-5" >Limpiar</Button>
                                        </div>
                                    </Grid>
                                }
                                {
                                    (phone) &&
                                    <Grid item md={4} lg={4} xs={4} sm={4}>
                                        <div className="txt-c">
                                            <Button onClick={() => searchUser()} className="bg-red mt-5" >Buscar</Button>
                                        </div>
                                    </Grid>
                                }
                                <Grid item md={phone ? 4 : 12} lg={phone ? 4 : 12} xs={phone ? 4 : 12} sm={phone ? 4 : 12}>
                                    <div className="txt-c">
                                        {
                                            (selectedClientToUpdate === null) ?
                                                <Button type="submit" className="bg-green3 mt-5" >Registrar</Button>
                                                : <Button onClick={() => updateClient()} className="bg-green3 mt-5" >Actualizar</Button>
                                        }

                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <form className="txt-c" onSubmit={handleBooking}>
                <Grid container spacing={2} >
                    <Grid item md={12} lg={12} xs={12} sm={12} className="txt-c" >
                        <center >
                            <Grid item md={6} lg={6} xs={12} sm={12} >
                                <img className="img-responsive border-rounded" src={mainImageUrl} />
                            </Grid>
                        </center>
                    </Grid>
                    <Grid item md={6} lg={6} xs={6} sm={6}>
                        <TextField variant="filled" fullWidth onChange={handleLodgingChange} label="Hospedaje" value={internalName} select>
                            {
                                basicLodgingList.map((lodging) => (
                                    <MenuItem key={lodging.id} value={lodging.internalName}>{lodging.name}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>

                    <Grid item md={6} lg={6} xs={6} sm={6}>
                        <Autocomplete
                            onChange={handleBookingChange}
                            options={clients.map(client => `${client.fullName}-${client.phone}`)}
                            value={selectedClientData()}
                            renderInput={(params) => <TextField fullWidth {...params} label="Cliente" variant="filled" />}
                        />
                    </Grid>
                    <Grid item md={6} lg={6} xs={6} sm={6}>
                        <TextField
                            fullWidth
                            label="Fecha entrada"
                            type="text"
                            value={formatStartDate}
                            variant="filled"
                            autoComplete="off"
                            disabled
                        />
                    </Grid>
                    <Grid item md={6} lg={6} xs={6} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="dialog"
                                format="dd/MM/yyyy"
                                label="Fecha salida"
                                value={formatEndDate}
                                onChange={handleBookingEndDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6} lg={6} xs={6} sm={6}>
                        <InputLabel className="pointer" style={{ textDecoration: 'underline' }}>Monto Total ?</InputLabel>
                        <TextField variant="filled" onChange={handleBookingChange} InputProps={{ inputProps: { min: 0 } }} name="totalAmount" type="number" fullWidth value={totalAmount} />
                    </Grid>
                    <Grid item md={6} lg={6} xs={6} sm={6}>
                        <InputLabel>Deposito</InputLabel>
                        <TextField variant="filled" onChange={handleBookingChange} InputProps={{ inputProps: { min: 0 } }} type="number" name="deposit" fullWidth value={deposit} />
                    </Grid>
                    <Grid item md={getGridSize()} lg={getGridSize()} xs={getGridSize()} sm={getGridSize()}>
                        <InputLabel>Adultos</InputLabel>
                        <TextField variant="filled" onChange={handleBookingChange} InputProps={{ inputProps: { min: minCapacity, max: maxCapacity } }} type="number" name="adults" fullWidth value={adults} />
                    </Grid>
                    <Grid item md={getGridSize()} lg={getGridSize()} xs={getGridSize()} sm={getGridSize()}>
                        <InputLabel>Niños</InputLabel>
                        <TextField variant="filled" onChange={handleBookingChange} InputProps={{ inputProps: { min: 0, max: maxCapacity } }} type="number" name="kids" fullWidth value={kids} />
                    </Grid>
                    {
                        (title === AVAILABLE) && <Grid item md={6} lg={6} xs={6} sm={6} className='mt-5'>
                            <InputLabel>Enviar correo</InputLabel>
                            <Checkbox inputProps={{ 'aria-label': 'controlled' }} name='sendEmail' checked={sendEmail} onChange={handleBookingChange} />
                        </Grid>
                    }

                    <Grid item md={12} lg={12} xs={12} sm={12}>
                        {
                            (title === AVAILABLE) && <Button type="submit" className="bg-green2" >
                                Reservar
                            </Button>
                        }
                        {
                            (title === BOOKED || title === PENDING)
                            &&
                            <Button onClick={() => handleDeleteBooking()} className="bg-red mt-5 ml-1" >Eliminar</Button>
                        }

                        <div className="subtitle-text pointer mt-3">
                            <h5 onClick={() => handleOpen()} style={{ textDecoration: 'underline' }}>
                                ¿ Registrar cliente ?
                            </h5>
                        </div>
                    </Grid>
                </Grid>
            </form>
            <br />
        </Paper >
    );
};

