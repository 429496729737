import axiosInstance from "./axiosInstance";

const getBasicLodgingList = async () => {
    let response;
    try {
        response = await axiosInstance.get(`lodging/basicList`);
    } catch (error) {
        console.error("api.lodging.getBasicLodgingList");
    }
    return response;
};

const getDetailedLodgingInformation = async (internalName) => {
    let response;
    try {
        response = await axiosInstance.get(`lodging/${internalName}`);
    } catch (error) {
        console.error("api.lodging.getDetailedLodgingInformation");
    }
    return response;
};

const addBookingAndUpdateUnavailableDates = async (lodgingId, newBooking, unavailableDates) => {
    let response;
    let request = {
        lodgingId,
        newBooking,
        unavailableDates
    };
    try {
        response = await axiosInstance.post(`lodging/addBooking`, request);
    } catch (error) {
        console.error("api.lodging.addBookingAndUpdateUnavailableDates");
    }
    return response;
};
const removeBookingAndClearUnavailableDates = async (lodgingId, bookings, unavailableDates) => {
    let response;
    let request = {
        lodgingId,
        bookings,
        unavailableDates
    };
    try {
        response = await axiosInstance.post(`lodging/removeBooking`, request);
    } catch (error) {
        console.error("api.lodging.removeBookingAndClearUnavailableDates");
    }
    return response;
};
export const lodgingService = {
    getBasicLodgingList,
    getDetailedLodgingInformation,
    addBookingAndUpdateUnavailableDates,
    removeBookingAndClearUnavailableDates
}