import React from 'react';
import { Footer } from '../ui/footer/Footer';
import { Navbar } from '../ui/navbar/Navbar';
import ScrollToTop from '../ui/scroll/ScrollToTop';
import { LoginForm } from './LoginForm';



export const LoginScreen = () => {

    

    return (
        <div className="main__overflow-hidden bg-white">
            <ScrollToTop />
            <Navbar />
            <LoginForm />
            <Footer />
        </div>
    );
};
