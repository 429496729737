import { Container } from '@material-ui/core'
import React from 'react'

export const Footer = ({ reference }) => {

    const openKennethWebPage = () => {
        const url = `http://www.kennethlopezporras.com/`;
        window.open(url, "_blank");
    }
    const openJeffersonWebPage = () => {
        const url = `http://www.jeffersonlopezporras.com/`;
        window.open(url, "_blank");
    }

    const openWhatsApp = () => {
        const url = `https://wa.me/50662385373`;
        window.open(url, "_blank");
    }
    return (
        <div className="bg-green2 mt-10 " style={{ minHeight: '200px' }}>
            <Container maxWidth="md">
                <div ref={reference} className="txt-c">
                    <br />
                    <img src={`https://res.cloudinary.com/djmhhp7hp/image/upload/v1708539310/favico_kpdami.png`} width={180} height={180} alt="logo" />
                    <h1 onClick={() => openWhatsApp()} className="subtitle-text mt-2 pointer">
                        {'WhatsApp : 62385373'}
                    </h1>
                    <br />
                    <h3 className="subtitle-text">Desarrollado por</h3>
                    <h3 onClick={() => openKennethWebPage()} className="subtitle-text mt-2 pointer">Kenneth López Porras</h3>
                    <h3 onClick={() => openJeffersonWebPage()} className="subtitle-text mt-2 pointer">Jefferson López Porras</h3>
                    <h3 className="subtitle-text mt-5">
                        &copy; {new Date().getFullYear()} Finca Buena Vista
                    </h3>
                    <h3 className="subtitle-text mt-2">
                        <a href="https://drive.google.com/file/d/1bGgtuxUC5zS573LVZiHFHunYrTnal6Gz/view?usp=sharing">Términos y condiciones</a>
                    </h3>
                    <br />
                </div>
            </Container>
        </div>
    );
};
