import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BOOKED, PENDING, REJECTED } from '../../../shared/constants';
import { Alert, AlertTitle, Button } from '@mui/material';
import { useSelector } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(id, client, startDate, endDate, total, status) {
  return { id, client, startDate, endDate, total, status };
}


const rows = [
  createData('1', 'Kenneth Lopez', '22-22-22', '23-22-22', 50000, PENDING),
  createData('2', 'Jefferon Lopez', '25-22-22', '26-22-22', 100000, BOOKED),
];

export default function BookingsTable() {

  const { activeLodging: { bookings, unavailableDates, isAvailableMidWeek } } = useSelector(state => state.lodging);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align='left'>ID</StyledTableCell>
            <StyledTableCell align="left">Cliente</StyledTableCell>
            <StyledTableCell align="left">Fecha Inicio</StyledTableCell>
            <StyledTableCell align="left">Fecha Fin</StyledTableCell>
            <StyledTableCell align="left">Deposito</StyledTableCell>
            <StyledTableCell align="left">Total</StyledTableCell>
            <StyledTableCell align="left">Estado</StyledTableCell>
            <StyledTableCell align="left">Comprobante y notas</StyledTableCell>
            <StyledTableCell align="left">Acción</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.slice(0).reverse().map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="left">{index + 1}</StyledTableCell>
              <StyledTableCell align="left">{row.clientData == null ? "No registrado" : row.clientData.fullName}</StyledTableCell>
              <StyledTableCell align="left">{row.dates[0]}</StyledTableCell>
              <StyledTableCell align="left">{row.dates[row.dates.length - 1]}</StyledTableCell>
              <StyledTableCell align="left">{row.deposit}</StyledTableCell>
              <StyledTableCell align="left">{row.totalAmount}</StyledTableCell>
              <StyledTableCell align="left">{getAlertStatusComponent(row.status)}</StyledTableCell>
              <StyledTableCell align="left"><Button variant="contained" color="info">
                Ver comprobantes
              </Button>
              </StyledTableCell>
              <StyledTableCell align="left">{getActionsByStatusComponent(row.status)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const getAlertStatusComponent = (bookingStatus) => {

  switch (bookingStatus) {
    case BOOKED:
      return <Alert severity="success"><AlertTitle>Reservada</AlertTitle></Alert>

    case PENDING:
      return <Alert severity="warning"><AlertTitle>Pendiente</AlertTitle></Alert>

    case REJECTED:
      return <Alert severity="error"><AlertTitle>Rechazada</AlertTitle></Alert>

  };
}

const getActionsByStatusComponent = (bookingStatus) => {

  switch (bookingStatus) {
    case PENDING:
      return <div >
        <Button variant='contained' color="success">
          Aceptar
        </Button>
        <Button className='ml-1' variant='contained' color="error">
          Rechazar
        </Button>
      </div>
  };
}