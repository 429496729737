import React from 'react';
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { setSelectedAdminOption, showAdminManagementModal } from '../../../actions/ui';
import { authGetClientList } from '../../../actions/auth';
import { informationGetClientList } from '../../../actions/information';

export const AdminOption = ({ data, columnSize }) => {

    const { id, title, subtitle, imageUrl } = data;
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setSelectedAdminOption(id));
        dispatch(showAdminManagementModal());
    };
    return (
        <Grid item md={columnSize} lg={columnSize} xs={12} sm={columnSize} >
            <div>
                <img onClick={handleClick} className="img-responsive border-rounded pointer" alt={title} src={imageUrl} />
            </div>
            <h3 className="subtitle-text mt-2">{title}</h3>
            <p className="info-text mt-2">{subtitle}</p>
        </Grid>
    );
};
