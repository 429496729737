import React from 'react';
import { Grid } from '@material-ui/core';

export const LodgingCard = ({ data }) => {

    const { name, shortDescription, longDescription, mainImageUrl } = data;

    return (
        <Grid className="txt-l" data-aos="fade-right" spacing={6} container style={{ marginBottom: '50px' }}>
            <Grid item md={5} lg={5} xs={12} sm={5} >
                <h1 className="title-text">{name}</h1>
                <h3 className="subtitle-text mt-2">{shortDescription}</h3>
                <p className="info-text mt-3 txt-justify">{longDescription}</p>
            </Grid>
            <Grid item md={7} lg={7} xs={12} sm={7} >
                <img className="img-responsive border-rounded" alt={name} src={mainImageUrl} />
            </Grid>
        </Grid>
    );
};
