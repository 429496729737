export const emptyFieldsMessage = () => (
    {
        title: 'Campos incorrectos',
        text: 'Favor ingresar y revisar todos los datos',
        icon: 'error',
    }
);

export const loginErrorMessage = () => (
    {
        title: 'Credenciales incorrectos',
        text: 'Favor verifique sus credenciales',
        icon: 'error',
    }
);

export const basicErrorMessage = () => (
    {
        title: 'Algo ocurrió mal',
        text: 'Favor intente de nuevo',
        icon: 'error',
    }
);

export const createClientSuccessMessage = () => (
    {
        title: 'Cliente registrado',
        text: 'El usuario fue registrado satisfactoriamente',
        icon: 'success',
    }
);

export const updateClientSuccessMessage = () => (
    {
        title: 'Cliente actualizado',
        text: 'El usuario fue actualizado satisfactoriamente',
        icon: 'success',
    }
);

export const createBookingSuccessMessage = () => (
    {
        title: 'Reserva Registrada',
        text: 'La reserva fue registrada satisfactoriamente',
        icon: 'success',
    }
);

export const removeBookingSuccessMessage = () => (
    {
        title: 'Reserva eliminada',
        text: 'La reserva fue eliminada satisfactoriamente',
        icon: 'success',
    }
);


