import { Container } from '@material-ui/core';
import React from 'react';

export const Loading = () => {
    return (
        <Container maxWidth="lg" className="txt-c mt-20" style={{ height: '70vh' }}>
            <div className="lds-facebook">
                <div /><div /><div />
            </div>
            <h1>Cargando</h1>
        </Container>
    );
};
