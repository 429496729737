import { Button, Container, Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { LodgingBasicInformation } from './LodgingBasicInformation';
import Carousel from 'react-grid-carousel'

export const LodgingList = () => {

    const { basicLodgingList } = useSelector(state => state.lodging);
    const columnSize = 12;
    return (
        <Container maxWidth="lg" className='mt-2'>
            <h1 className="title-text txt-l">Tipos de Hospedaje</h1>
            <div className="mt-2">
                <Carousel
                    cols={4}
                    rows={1}
                    responsiveLayout={[
                        {
                            breakpoint: 1200,
                            cols: 4
                        },
                        {
                            breakpoint: 990,
                            cols: 3
                        }
                    ]}
                    mobileBreakpoint={670}
                    loop={true}
                    showDots={true}
                    autoplay={3500}
                    dotColorActive={'green'}
                    hideArrow={false}
                >
                    {
                        basicLodgingList.map((lodging, i) => (
                            <Carousel.Item key={i}>
                                <LodgingBasicInformation key={lodging.id} data={lodging} columnSize={columnSize} />
                            </Carousel.Item>)
                        )
                    }
                </Carousel>
            </div>
        </Container>
    );
};