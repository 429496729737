import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FeaturesList } from './FeaturesList';

export const FeaturesModal = ({placeId}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button onClick={handleClickOpen()} className="bg-red">Ver lista de servicios</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{placeId}</DialogTitle>
                <DialogContent dividers>
                    <FeaturesList/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className="bg-red">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
