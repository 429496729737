import axios from "axios";
import { baseURL } from "../../shared/constants";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
    config.baseURL = baseURL;
    //   if (config.url === '/login/login' || config.url === '/login/refresh') {//request without token
    //     return config;
    //   }
    //   config.headers.common["Authorization"] = `Bearer ${store.state.accessToken}`;//request with token
    return config;
});

// axiosInstance.interceptors.response.use(

//   (response) => {
//     return response;
//   },
//   async (error) => {
//     if (error.config.url === '/login/refresh') {
//       router.push({ path: `/login` });//logout with any error
//       return Promise.reject(error);
//     }

//     let errorMessage = error.response.data.message;
//     if (error.response.status === StatusCodes.FORBIDDEN && errorMessage === ACCESS_DENIED) {//if the error is 403, try to refresh
//       const tokenRefresh = {
//         tokenRefresh: store.state.refreshToken,
//       };
//       let response = await axiosInstance.post(
//         `/login/refresh`,
//         tokenRefresh
//       );
//       let { data: { refreshToken, accessToken } } = response;

//       if (!refreshToken || !accessToken) return Promise.reject(error);

//       //save new refresh and accessToken
//       store.commit("setRefreshToken", refreshToken);
//       store.commit("setAccessToken", accessToken);
//       error.config.headers["Authorization"] = `Bearer ${accessToken}`;
//       return axios(error.config);
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

export default axiosInstance;