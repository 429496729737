import { types } from "../types/types";

const initialState = {
    information: {},
    nearbyPlacesList: [],
    clients: []
};

export const informationReducer = (state = initialState, action) => {


    switch (action.type) {


        case types.informationLoaded:
            return {
                ...state,
                information: action.payload.information,
                nearbyPlacesList: action.payload.nearbyPlaces,
            }
        case types.authSetClients:
            return {
                ...state,
                clients: action.payload
            }
        default:

            return state;
    }

}