import React from 'react';
import { Grid, Paper } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css';

export const Gallery = () => {

    const [showBedroomImages, setBedroomImages] = useState(false);
    const [showIndoorImages, setIndoorImages] = useState(false);
    const [showOutdoorImages, setOutdoorImages] = useState(false);

    const { activeLodging } = useSelector(state => state.lodging);
    const { bedroomImages, indoorImages, outdoorImages } = activeLodging;

    const { photo: bedroom } = bedroomImages[0];
    const { photo: indoor } = indoorImages[0];
    const { photo: outdoor } = outdoorImages[0];

    return (
        <div className="mt-10">
            <h1 className="title-text">Galería</h1>
            <Grid container spacing={2} className="">
                <Grid className="mt-3" item lg={4} sm={4} xs={12}>
                    <Paper className="bg-white pointer" onClick={() => setBedroomImages(true)} elevation={3}>
                        <img alt="foto" className="img-responsive border-rounded" alt="foto" src={bedroom} />
                        <div className="txt-c subtitle-text">
                            Dormitorios <OpenInNewIcon fontSize="small" className="" />
                        </div>
                    </Paper>
                </Grid>
                <Grid className="mt-3" item lg={4} sm={4} xs={12}>
                    <Paper className="bg-white pointer" onClick={() => setIndoorImages(true)} elevation={3}>
                        <img alt="foto" className="img-responsive border-rounded" src={indoor} />
                        <div className="txt-c subtitle-text">
                            Interiores <OpenInNewIcon fontSize="small" className="" />
                        </div>
                    </Paper>
                </Grid>
                <Grid className="mt-3" item lg={4} sm={4} xs={12}>
                    <Paper className="bg-white pointer" onClick={() => setOutdoorImages(true)} elevation={3}>
                        <img alt="foto" className="img-responsive border-rounded" src={outdoor} />
                        <div className="txt-c subtitle-text">
                            Exteriores <OpenInNewIcon fontSize="small" className="" />
                        </div>
                    </Paper>
                </Grid>
            </Grid>

            <ReactBnbGallery
                show={showBedroomImages}
                photos={bedroomImages}
                onClose={() => setBedroomImages(false)}
            />
            <ReactBnbGallery
                show={showIndoorImages}
                photos={indoorImages}
                onClose={() => setIndoorImages(false)}
            />
            <ReactBnbGallery
                show={showOutdoorImages}
                photos={outdoorImages}
                onClose={() => setOutdoorImages(false)}
            />

        </div>
    );
};
