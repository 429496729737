import { Container, Grid } from '@material-ui/core';
import React from 'react'
import BookingsTable from './BookingsTable';

export const ContentManagement = () => {
    return (
        <Container data-aos="fade-left" className="mt-10" maxWidth="lg">
            <h1 className="title-text txt-l">Tabla de reservas</h1>
            <Grid className="mt-1 txt-c bg-green1" spacing={6} container>
                <BookingsTable />
            </Grid>
        </Container>
    );
};
