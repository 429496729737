import { Container, Grid } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { informationGetClientList } from '../../../actions/information';
import { lodgingDetailedInformationStartLoading } from '../../../actions/lodging';
import { AdminCalendar } from './AdminCalendar';
import { BookingForm } from './BookingForm';
import BookingsTable from './BookingsTable';

export const BookingManagement = () => {

    const dispatch = useDispatch();
    const { basicLodgingList } = useSelector(state => state.lodging);
    const defaultInternalName = basicLodgingList[0].internalName;

    useEffect(() => {
        dispatch(lodgingDetailedInformationStartLoading(defaultInternalName));
        dispatch(informationGetClientList());
    }, [dispatch]);

    return (
        <Container data-aos="fade-left" className="mt-10" maxWidth="lg">
            <Grid className="mt-1 txt-c" spacing={6} container>
                <Grid item md={7} lg={7} xs={12} sm={12} >
                    <AdminCalendar />
                </Grid>
                <Grid item md={5} lg={5} xs={12} sm={12} >
                    <BookingForm />
                </Grid>
            </Grid>
            <Grid className="mt-4 txt-c" spacing={6} container>
                <Grid item md={12} lg={12} xs={12} sm={12} >
                    <BookingsTable />
                </Grid>
            </Grid>

        </Container>
    );
};
