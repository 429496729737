import React from 'react';
import { Box, Button, Container, Grid } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { LodgingList } from '../Lodging/LodgingList';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const styles = theme => ({
    jumbotron: {
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center'
        },
    }
});
const InformationLanding = ({ classes, executeScroll }) => {
    const { information } = useSelector(state => state.information);
    const shortDescriptionP1 = 'Descubre la verdadera esencia de Turrialba en nuestras cabañas, donde la naturaleza es tu vecina más cercana. Con vistas impresionantes y la serenidad del lugar, podrás disfrutar una experiencia de hospedaje única. Desde acogedoras cabañas para 2 personas hasta nuestra cabaña para 20 personas con piscina privada, tenemos opciones para todos los gustos y necesidades.';
    return (
        <Container maxWidth="lg">
            <Grid container>
                <Grid item md={12} lg={12} xs={12} sm={12} >
                    <Box className='main__jumbotron-text' >
                        <Container maxWidth="lg">
                            <h1 className='mt-2'>{information.name}</h1>
                            <p className='mt-5'>{shortDescriptionP1}</p>
                            <div className='txt-c'>
                                <Button className="bg-green2 mt-4 subtitle-text" onClick={() => window.open("https://wa.me/50662385373?text=Hola, quiero hacer una reservación. Gracias", "_blank")}>
                                    <h3>Reservar ahora</h3>
                                </Button>
                            </div>
                            {/* <div className='txt-c'>
                            <Link style={{ textDecoration: 'none' }} to="/info/contact">
                                <Button className="bg-red mt-5 subtitle-text"><h3>Contacto</h3></Button>
                            </Link>
                        </div> */}
                        </Container>
                    </Box>
                </Grid>
                <Grid item md={12} lg={12} xs={12} sm={12} >
                    <LodgingList />
                </Grid>
            </Grid>
        </Container >
    );
};

export default withStyles(styles)(InformationLanding);
