
import { types } from "../types/types";

export const loadingStart = () => ({
    type: types.uiLoadingStart
})

export const loadingStop = () => ({
    type: types.uiLoadingStop
})

export const showModal = () => ({
    type: types.uiModalShow
})

export const HideModal = () => ({
    type: types.uiModalHide
})
export const setBookingData = (data) => ({
    type: types.uiSetModalData,
    payload: data
})
export const clearBookingData = () => ({
    type: types.uiClearModalData
})

export const setSelectedAdminOption = (selectedOption) => ({
    type: types.uiSetSelectedAdminOption,
    payload : selectedOption
})

export const showAdminManagementModal = () => ({
    type: types.uiAdminManagementModalShow
})

export const hideAdminManagementModal = () => ({
    type: types.uiAdminManagementModalHide
})