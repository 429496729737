
import Swal from 'sweetalert2';

export const showSwalAlert = ({title, text, icon}) => {
    Swal.fire({
        title,
        text,
        icon,
        confirmButtonText: 'OK',
    })
};