import React from 'react'
import { useParams } from 'react-router-dom';
import { Footer } from '../ui/footer/Footer';
import { Navbar } from '../ui/navbar/Navbar';
import ScrollToTop from '../ui/scroll/ScrollToTop';
import { LodgingDetailedInformation } from './Lodging/LodgingDetailedInformation';


export const LodgingDetailScreen = () => {

    let { internal_name } = useParams();

    return (
        <div className="main__overflow-hidden bg-white">
            <ScrollToTop />
            <Navbar />
            <LodgingDetailedInformation internal_name={internal_name} />
            <Footer />
        </div>
    );
};
