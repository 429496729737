import React from 'react';
import { Box, Button, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom';

export const LodgingBasicInformation = ({ data, columnSize }) => {

    const { name, shortDescription, mainImageUrl, internalName } = data;

    const history = useHistory();
    const handleClick = () => history.push(`/${internalName}`);
    return (
        <Box className='txt-c' p={1}>
            <img onClick={handleClick} className="img-responsive border-rounded pointer" alt={name} src={mainImageUrl} />
            <h1 className="subtitle-text mt-2">{name}</h1>
            <p className="info-text mt-2">{shortDescription}</p>
            {/* <div className="mt-2">
                <Button onClick={handleClick} className="bg-red">Ver detalles</Button>
            </div> */}
        </Box>
    );
};