import { types } from "../types/types";

const initialState = {
    isLoggedIn: false,
    user: {},
    clients: []
};

export const authReducer = (state = initialState, action) => {


    switch (action.type) {


        case types.authLogin:
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true
            }

        case types.authSetClients:
            return {
                ...state,
                clients: action.payload
            }

        default:

            return state;
    };
};