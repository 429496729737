import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { adminOptions } from '../../../shared/util';
import { AdminOption } from './AdminOption';
import { ManagementModal } from './ManagementModal';

export const AdminDashboardOptions = () => {
    const { user: { name } } = useSelector(state => state.auth);

    const columnSize = 6 || 12 / adminOptions.length;
    return (
        <Container data-aos="fade-left" className="mt-10" maxWidth="lg">
            <h1 className="title-text txt-l">Bienvenido {name}</h1>
            <h3 className="subtitle-text mt-2">En esta sección podrá gestionar reservas, contenido de la página y demás</h3>
            <Grid className="mt-1 txt-c" spacing={6} container>
                {adminOptions.map((option) => (<AdminOption key={option.id} data={option} columnSize={columnSize} />))}
            </Grid>

            <ManagementModal />

        </Container>
    );
};