import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { informationReducer } from "./informationReducer";
import { lodgingReducer } from "./lodgingReducer";
import { uiReducer } from "./uiReducer";

export const rootReducer = combineReducers({
    ui: uiReducer,
    information: informationReducer,
    lodging: lodgingReducer,
    auth: authReducer
})