
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Grid, InputLabel, makeStyles, Paper, TextField } from '@material-ui/core'
import Modal from '@mui/material/Modal';
import Chip from '@material-ui/core/Chip';

import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import CustomToolbar from './CustomToolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { ColoredDateCellWrapper, customDayColor, getEventsList, getMessageDataByBookingTitle } from '../../../../shared/util';

import { AVAILABLE, BOOKED, PENDING, UNAVAILABLE } from '../../../../shared/constants';

import moment from 'moment';
import 'moment/locale/es';
import { CalendarModal } from './CalendarModal';
import { lodgingSetActiveEvent } from '../../../../actions/lodging';
import { setBookingData, showModal } from '../../../../actions/ui';
moment.locale('es');
const localizer = momentLocalizer(moment);
const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"
];
export const Calendar = () => {

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const dispatch = useDispatch();
    const { activeLodging: { name, bookings, unavailableDates, isAvailableMidWeek, minCapacity, maxCapacity, pendingDates }, activeEvent } = useSelector(state => state.lodging);
    const [showClientModal, setShowClientModal] = useState(false);
    const [personQuantity, setPersonQuantity] = useState(minCapacity);
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');

    const openWhatsApp = (e) => {
        e.preventDefault();
        const date = activeEvent.start.getDate();
        const month = monthNames[activeEvent.start.getMonth()];
        const year = activeEvent.start.getFullYear();
        const url = `https://wa.me/50662385373?text=Buenas, mi nombre es ${clientName} y me gustaría hacer una reserva para el ${date} de ${month} del ${year} en ${name} para ${personQuantity} personas. Muchas gracias.`;
        window.open(url, "_blank");
    }
    const handleClickedDay = (event) => {
        dispatch(lodgingSetActiveEvent(event));
        dispatch(setBookingData(getMessageDataByBookingTitle(event.title)));
        dispatch(showModal());

        if (event.title === AVAILABLE) {
            setShowClientModal(true);
        }
    };
    const events = getEventsList(bookings, unavailableDates, isAvailableMidWeek,pendingDates);
    const handleClientModalClose = () => {
        setShowClientModal(false);
    }
    const classes = useStyles();
    return (
        <div className="mt-10">
            <CalendarModal />
            <Modal
                open={showClientModal}
                onClose={handleClientModalClose}
                className={classes.modal}
            >
                <div className={`${classes.paper} border-rounded`}>
                    <center><h1 className='mb-5'>Favor complete los datos para gestionar la reserva</h1></center>
                    <form onSubmit={openWhatsApp}>
                        <Grid container spacing={2} >
                            <Grid className="" item md={6} lg={6} xs={6} sm={6}>
                                <InputLabel >Hospedaje</InputLabel>
                                <TextField required disabled variant="filled" value={name} fullWidth />
                            </Grid>
                            <Grid className="" item md={6} lg={6} xs={6} sm={6}>
                                <InputLabel >Fecha</InputLabel>
                                <TextField required disabled variant="filled" value={`${new Date(activeEvent.start).getDate()} de ${monthNames[new Date(activeEvent.start).getMonth()]} del ${new Date(activeEvent.start).getFullYear()}`} fullWidth />
                            </Grid>
                            <Grid className="" item md={3} lg={3} xs={12} sm={12}>
                                <InputLabel >Nombre Completo</InputLabel>
                                <TextField required variant="filled" onChange={(e) => setClientName(e.target.value)} value={clientName} fullWidth />
                            </Grid>
                            <Grid className="" item md={3} lg={3} xs={12} sm={12}>
                                <InputLabel >Número WhatsApp</InputLabel>
                                <TextField required variant="filled" onChange={(e) => setClientPhone(e.target.value)} value={clientPhone} fullWidth />
                            </Grid>
                            <Grid className="" item md={3} lg={3} xs={12} sm={12}>
                                <InputLabel >Correo Electrónico</InputLabel>
                                <TextField required variant="filled" onChange={(e) => setClientEmail(e.target.value)} value={clientEmail} fullWidth />
                            </Grid>
                            <Grid className="" item md={3} lg={3} xs={12} sm={12}>
                                <InputLabel >Cantidad de Personas</InputLabel>
                                <TextField required type='number' variant="filled" value={personQuantity} onChange={(e) => setPersonQuantity(e.target.value)} InputProps={{ inputProps: { min: minCapacity, max: maxCapacity, step: 1 } }} fullWidth />
                            </Grid>
                            <Grid item md={12} lg={12} xs={12} sm={12}>
                                <center>
                                    <Button type='submit' className="bg-green2 mt-5" >Finalizar reserva en WhatsApp</Button>
                                </center>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Modal>
            <h1 className="title-text">Disponibilidad</h1>
            <Grid container spacing={5}>
                <Grid className="mt-3" item lg={12} xs={12} sm={12}>
                    <Paper style={{ backgroundColor: 'lightgray' }} elevation={3} >
                        <BigCalendar
                            className="border-rounded"
                            min={new Date()}
                            localizer={localizer}
                            view={'month'}
                            onSelectEvent={(event, e) => handleClickedDay(event)}
                            components={{
                                toolbar: CustomToolbar,
                                dateCellWrapper: ColoredDateCellWrapper
                            }}
                            onView={() => { }}
                            eventPropGetter={
                                (event, start, end, isSelected) => customDayColor(event, start, end, isSelected)
                            }
                            events={events}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <div className="mt-3 txt-c" >
                <Chip className="mr-1 bg-green3" label={AVAILABLE} />
                <Chip className="mr-1 bg-red" label={BOOKED} />
                {/* <Chip className="mr-1 bg-yellow" label={UNAVAILABLE} /> */}
                <Chip className="mr-1 bg-orange" label={PENDING} />
            </div>
        </div>
    );
};