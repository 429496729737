import axiosInstance from "./axiosInstance";

const getInformation = async () => {
    let response;
    try {
        response = await axiosInstance.get(`information`);
    } catch (error) {
        console.error("api.information.getInformation");
    }
    return response;
};

const getNearbyPlacesList = async () => {
    let response;
    try {
        response = await axiosInstance.get(`information/nearbyPlaces`);
    } catch (error) {
        console.error("api.information.getNearbyPlacesList");
    }
    return response;
};


export const informationService = {
    getInformation,
    getNearbyPlacesList
}