export const types = {
    //ui
    uiLoadingStart: '[ui] UI Start Loading',
    uiLoadingStop: '[ui] UI Stop Loading',
    uiModalShow: '[ui] Show Modal',
    uiModalHide: '[ui] UI Hide Modal',
    uiSetModalData: '[ui] Set Modal Data',
    uiClearModalData: '[ui] Clear Modal Data',
    uiSetSelectedAdminOption: '[ui] Set Selected Admin Option',
    uiAdminManagementModalShow: '[ui] Show Admin Management Modal',
    uiAdminManagementModalHide: '[ui] Hide Admin Management Modal',
    //information
    informationLoaded: '[information] Information Loaded',

    //lodging
    lodgingBasicListLoaded: '[lodging] Basic List Loaded',
    lodginDetailedInformationLoaded: '[lodging] Detailed Information Loaded',
    lodgingClearActive: '[lodging] Clear Active Lodging',
    lodgingSetActiveEvent: '[lodging] Set Active Event',
    lodgingClearActiveEvent: '[lodging] Clear Active Event',
    lodgingSetActiveBooking: '[lodging] Set Active Booking',
    lodgingClearActiveBooking: '[lodging] Clear Active Booking',
    lodgingUpdateClientActiveBooking: '[lodging] Update Client Active Booking',
    lodgingUpdateEndActiveBooking: '[lodging] Update End Active Booking',
    lodgingUpdateTotalAmountActiveBooking: '[lodging] Update TotalAmount Active Booking',
    lodgingUpdateDepositActiveBooking: '[lodging] Update Deposit Active Booking',
    lodgingUpdateAdultsActiveBooking: '[lodging] Update Adults Active Booking',
    lodgingUpdateKidsActiveBooking: '[lodging] Update Kids Active Booking',
    lodgingUpdateSendEmailActiveBooking: '[lodging] Update Send Email Flag Active Booking',

    //auth
    authLogin: '[auth] Login',
    authSetClients: '[auth] Set Clients List',
}